.site-page-header {
  overflow: hidden;
  padding : 5px;
}

html,
body {
  margin: 0px;
  height: 100%;
}

#root {
  background: #fff;
  height    : 100%;
}

.trigger {
  color      : white !important;
  font-size  : 18px;
  line-height: 64px;
  padding    : 0 24px;
  cursor     : pointer;
  transition : color 0.3s;
}

.trigger:hover {
  color: #1890ff !important;
}

.main-spinner {
  height         : 100%;
  display        : flex;
  justify-content: center;
  align-items    : center;
}

.dark {
  background: #000;
}

.light {
  background: #fff;
}

.sn-background-spin {
  -webkit-animation: spin 15s linear infinite;
  animation        : spin 15s linear infinite;
}

@keyframes spin{
	from{transform:rotate(0deg)}
	to{transform:rotate(360deg)}
}